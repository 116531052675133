<template>
  <v-row justify="center">
    <v-col sm="10" md="8" lg="6" align="stretch">
      <h1 class="ps-3 text-h5">Distribute Funds to Investors</h1>
      <distribution-form :project="project"></distribution-form>
    </v-col>
  </v-row>
</template>

<script>
import DistributionForm from './DistributionForm.vue'

export default {
  name: 'Distribute',
  components: {
    DistributionForm,
  },
  data() {
    return {
      project: {},
    }
  },
  mounted() {
    this.project = this.$store.getters.getProject(this.$route.params.slug)
  },
}
</script>
