<template>
  <v-container>
    <verification-alert></verification-alert>

    <alert :text="alertText" :type="alertType"></alert>

    <v-form v-if="$auth.isVerified($store.getters.wallet)" ref="distributionForm" v-model="formValid" max-width="500px">
      <p v-if="contract.name" class="text-h6">
        {{ contract.name }} ({{ contract.symbol }})
      </p>

      <p v-if="balance" class="mb-6">
        <span class="d-block">Stablecoin: <a class="text--primary text-decoration-none" target="_blank" :href="`${$store.getters.etherscanURL}/token/${contract.stablecoin}`">{{ contract.stablecoinSymbol }}</a></span>
        <span v-if="balance >= 0" class="d-block">Wallet balance: {{ balance | toCurrency }}</span>
        <span v-if="approved" class="d-block">Approved: {{ allowance | toCurrency }}</span>
      </p>

      <v-text-field
        v-model="amount"
        :prepend-inner-icon="icons.mdiCurrencyUsd"
        :disabled="approved"
        label="Distribution Amount"
        hide-details="auto"
        outlined
        rounded
        class="mb-6"
        type="number"
        value="100"
        :rules="[minAmount, maxAmount, availableAmount]"
      ></v-text-field>

      <v-btn block rounded color="primary" class="mb-4" :loading="approving" :disabled="approved || !amount || !formValid" @click="approve">
        Approve
        <v-icon v-if="approved" right>
          {{ icons.mdiCheck }}
        </v-icon>
      </v-btn>

      <v-btn block rounded color="primary" class="mb-4" :loading="distributing" :disabled="distributed || !approved" @click="distribute">
        Distribute
        <v-icon v-if="distributed" right>
          {{ icons.mdiCheck }}
        </v-icon>
      </v-btn>

      <p class="text-center text-caption mb-0">
        The <i>approve</i> and <i>distribute</i> steps are both required to make a distribution. Each step can take a few minutes to be confirmed.
      </p>
    </v-form>
  </v-container>
</template>

<script>
import { mdiCurrencyUsd, mdiCheck } from '@mdi/js'
import VerificationAlert from '@/components/VerificationAlert.vue'
import Alert from '@/components/Alert.vue'

export default {
  components: { VerificationAlert, Alert },
  setup() {
    return {
      icons: {
        mdiCurrencyUsd,
        mdiCheck,
      },
    }
  },
  props: {
    project: {
      type: Object.new,
      default: {},
    },
  },
  data() {
    return {
      contract: {},
      balance: undefined,
      allowance: undefined,
      amount: undefined,
      formValid: false,
      approving: false,
      approved: false,
      distributing: false,
      distributed: false,
      alertText: undefined,
      alertType: undefined,
    }
  },
  watch: {
    'project.address': function(address, oldAddress) {
      if (address) {
        this.$store.commit('setContract', { address: this.project.address })
        this.loadContract()
        this.getBalanceAllowance()
      }
    },
    '$store.state.chainId': function(chainId, oldChainId) {
      if (oldChainId && this.project.address) {
        this.$store.commit('setContract', { address: this.project.address })
        this.loadContract()
        this.getBalanceAllowance()
      }
    },
    '$store.state.wallet': function(wallet, oldWallet) {
      if (wallet && this.contract.stablecoin) {
        this.getBalanceAllowance()
      } else {
        this.balance = undefined
        this.allowance = undefined
        this.$refs.distributionForm.validate()
      }
    },
  },
  mounted() {
    if (this.$auth.isVerified(this.$store.getters.wallet) && this.project.address) {
      this.loadContract()
    }
  },
  methods: {
    loadContract() {
      this.$store.dispatch('loadContract', this.project.address).then((contract) => {
        this.contract = contract
        this.alertText = undefined
        this.alertType = undefined
        this.getBalanceAllowance()
      }).catch((e) => {
        this.contract = {}
        this.alertText = 'Wrong network selected. Please switch to ' + this.$store.getters.platformName(this.project.chain_id) + '.'
        this.alertType = 'warning'
      })
    },
    minAmount() {
      return !this.amount || Number(this.amount) >= 100 || 'Minimum distribution is $100'
    },
    maxAmount() {
      return !this.amount || Number(this.amount) <= 1000000 || 'Maximum distribution is $1,000,000'
    },
    availableAmount() {
      var result

      if (this.balance >= 0) {
        result = !this.amount || Number(this.amount) <= Number(this.balance) || 'Your wallet balance is ' + this.$options.filters.toCurrency(this.balance)
      } else {
        result = !this.amount || 'Please connect your wallet'
      }

      return result
    },
    getBalanceAllowance() {
      var wallet = this.$store.getters.wallet

      if (wallet && this.contract.stablecoin && this.contract.stablecoinDecimals) {
        var stablecoin = new this.$web3.eth.Contract(this.$ERC20ABI, this.contract.stablecoin)

        stablecoin.methods.balanceOf(wallet).call().then((balance) => {
          this.balance = this.$fromWei(balance, this.contract.stablecoinDecimals)
          this.$refs.distributionForm.validate()

          stablecoin.methods.allowance(wallet, this.project.address).call().then((allowance) => {
            this.allowance = this.$fromWei(allowance, this.contract.stablecoinDecimals)
          }).catch((error) => {
            this.showError(error)
          })
        }).catch((error) => {
          this.showError(error)
        })
      }
    },
    approve() {
      this.approving = true
      var wallet = this.$store.getters.wallet

      if (wallet && this.contract.stablecoin && this.contract.stablecoinDecimals) {
        var stablecoin = new this.$web3.eth.Contract(this.$ERC20ABI, this.contract.stablecoin)
        var amount = this.$toWei(this.amount, this.contract.stablecoinDecimals)

        stablecoin.methods.approve(this.project.address, amount).estimateGas({ from: wallet }).then((gasLimit) => {
          this.$web3.eth.getGasPrice().then((gasPrice) => {
            stablecoin.methods.approve(this.project.address, amount).send({ from: wallet, gasLimit, gasPrice })
              .on('receipt', () => {
                this.approving = false
                this.approved = true
                this.getBalanceAllowance()
              })
              .on('error', (error) => {
                this.showError(error)
              })
          }).catch((error) => {
            this.showError(error)
          })
        }).catch((error) => {
          this.showError(error)
        })
      }
    },
    distribute() {
      this.distributing = true
      var wallet = this.$store.getters.wallet

      var instance = new this.$web3.eth.Contract(this.$PartnershipFlipABI, this.project.address)
      var amount = this.$toWei(this.amount, this.contract.stablecoinDecimals)

      instance.methods.distribute(this.contract.stablecoin, amount).estimateGas({ from: wallet }).then((gasLimit) => {
        this.$web3.eth.getGasPrice().then((gasPrice) => {
          instance.methods.distribute(this.contract.stablecoin, amount).send({ from: wallet, gasLimit, gasPrice })
            .on('receipt', () => {
              this.distributing = false
              this.distributed = true
              this.alertText = 'Distribution completed successfully'
              this.alertType = 'success'
              this.getBalanceAllowance()
              this.$root.$emit('Distribution')
              this.$store.commit('transactionsClear')
              this.$store.commit('investmentsClear')
            })
            .on('error', (error) => {
              this.showError(error)
            })
        }).catch((error) => {
          this.showError(error)
        })
      }).catch((error) => {
        this.showError(error)
      })
    },
    showError(error) {
      this.approving = false
      this.flipping = false
      var match = error.message.match(/"message": "(.*)"/)
      this.alertText = match ? match[1] : error.message
      this.alertType = 'error'
    },
  },
}
</script>
